import Pusher from "pusher-js";
import Echo from "laravel-echo";
import { type AsyncData } from "#app";

export default function usePusher() {
  const config = useRuntimeConfig();
  //@ts-ignore
  window.Pusher = Pusher;
  let echo = new Echo({
    broadcaster: "pusher",
    key: config.public.pusherKey,
    cluster: "us2",
    forceTLS: true,
    //encrypted: import.meta.env.MODE !== 'development',
    encrypted: false,
    enabledTransports: ["ws", "wss"],
    //@ts-ignore
    authorizer: (channel, options) => {
      return {
        authorize: async (
          socketId: any,
          callback: (arg0: boolean, arg1: any) => void,
        ) => {
          let { data, error } = (await useApiFetch("/api/broadcasting/auth", {
            method: "POST",
            body: {
              socket_id: socketId,
              channel_name: channel.name,
            },
          })) as AsyncData<any, any>;

          if (error.value) {
            callback(true, error.value);
            return;
          }
          callback(false, data.value);
        },
      };
    },
  });
  return {
    echo,
  };
}
